import { NodeIdAnswersResolver } from '@breathelife/questionnaire-engine';
import { AnnuitantEntity, AnnuitantSelector, Answers } from '@breathelife/types';

import { InvalidAnnuitantError } from '../errors/invalidAnnuitantError';
import { isValidDateString, isGenderAtBirth } from '../helpers/answerTypeValidation';
import { PartiesEntityGenerator } from './PartiesEntityGenerator';

export class AnnuitantEntityGenerator extends PartiesEntityGenerator<AnnuitantEntity, AnnuitantSelector> {
  constructor(answersResolver: NodeIdAnswersResolver, entitySelector: AnnuitantSelector) {
    super(answersResolver, entitySelector);
  }

  get(answers: Answers): AnnuitantEntity {
    const title = this.answersResolver.getAnswer(answers, this.entitySelector.title);
    const firstName = this.answersResolver.getAnswer(answers, this.entitySelector.firstName);
    const middleName = this.answersResolver.getAnswer(answers, this.entitySelector.middleName);
    const lastName = this.answersResolver.getAnswer(answers, this.entitySelector.lastName);
    const dateOfBirth = this.answersResolver.getAnswer(answers, this.entitySelector.dateOfBirth);
    const gender = this.answersResolver.getAnswer(answers, this.entitySelector.gender);
    const formerName = this.entitySelector.formerName
      ? this.answersResolver.getAnswer(answers, this.entitySelector.formerName)
      : undefined;
    const email = this.answersResolver.getAnswer(answers, this.entitySelector.email);
    const telephoneNumber = this.answersResolver.getAnswer(answers, this.entitySelector.telephoneNumber);
    const street = this.entitySelector.street
      ? this.answersResolver.getAnswer(answers, this.entitySelector.street)
      : undefined;
    const apartment = this.entitySelector.apartment
      ? this.answersResolver.getAnswer(answers, this.entitySelector.apartment)
      : undefined;
    const city = this.entitySelector.city
      ? this.answersResolver.getAnswer(answers, this.entitySelector.city)
      : undefined;
    const province = this.entitySelector.province
      ? this.answersResolver.getAnswer(answers, this.entitySelector.province)
      : undefined;
    const postalCode = this.entitySelector.postalCode
      ? this.answersResolver.getAnswer(answers, this.entitySelector.postalCode)
      : undefined;

    if (typeof title !== 'undefined' && typeof title !== 'string') {
      throw new InvalidAnnuitantError(
        'Error while getting the Annuitant entity: title should be a string or undefined',
      );
    }

    if (typeof firstName !== 'undefined' && typeof firstName !== 'string') {
      throw new InvalidAnnuitantError(
        'Error while getting the Annuitant entity: firstName should be a string or undefined',
      );
    }

    if (typeof middleName !== 'undefined' && typeof middleName !== 'string') {
      throw new InvalidAnnuitantError(
        'Error while getting the Annuitant entity: middleName should be a string or undefined',
      );
    }

    if (typeof lastName !== 'undefined' && typeof lastName !== 'string') {
      throw new InvalidAnnuitantError(
        'Error while getting the Annuitant entity: lastName should be a string or undefined',
      );
    }

    if (typeof dateOfBirth !== 'undefined' && !isValidDateString(dateOfBirth)) {
      throw new InvalidAnnuitantError(
        'Error while getting the Annuitant entity: dateOfBirth should be a valid date or undefined',
      );
    }

    if (typeof gender !== 'undefined' && !isGenderAtBirth(gender)) {
      throw new InvalidAnnuitantError(
        'Error while getting the Annuitant entity: gender at brith should be male, female or undefined',
      );
    }

    if (typeof formerName !== 'undefined' && typeof formerName !== 'string') {
      throw new InvalidAnnuitantError(
        'Error while getting the Annuitant entity: formerName should be a string or undefined',
      );
    }

    if (typeof email !== 'undefined' && typeof email !== 'string') {
      throw new InvalidAnnuitantError(
        'Error while getting the Annuitant entity: email should be a string or undefined',
      );
    }

    if (typeof telephoneNumber !== 'undefined' && typeof telephoneNumber !== 'string') {
      throw new InvalidAnnuitantError(
        'Error while getting the Annuitant entity: telephoneNumber should be a string or undefined',
      );
    }

    if (typeof street !== 'undefined' && typeof street !== 'string') {
      throw new InvalidAnnuitantError(
        'Error while getting the Annuitant entity: street should be a string or undefined',
      );
    }

    if (typeof apartment !== 'undefined' && typeof apartment !== 'string') {
      throw new InvalidAnnuitantError(
        'Error while getting the Annuitant entity: apartment should be a string or undefined',
      );
    }

    if (typeof city !== 'undefined' && typeof city !== 'string') {
      throw new InvalidAnnuitantError('Error while getting the Annuitant entity: city should be a string or undefined');
    }

    if (typeof province !== 'undefined' && typeof province !== 'string') {
      throw new InvalidAnnuitantError(
        'Error while getting the Annuitant entity: province should be a string or undefined',
      );
    }

    if (typeof postalCode !== 'undefined' && typeof postalCode !== 'string') {
      throw new InvalidAnnuitantError(
        'Error while getting the Annuitant entity: postalCode should be a string or undefined',
      );
    }

    return {
      title,
      firstName,
      middleName,
      lastName,
      dateOfBirth: dateOfBirth ? new Date(dateOfBirth as string) : undefined,
      gender,
      formerName,
      email,
      telephoneNumber,
      street,
      apartment,
      city,
      province,
      postalCode,
    };
  }
}
