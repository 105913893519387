import { NodeIdAnswersResolver } from '@breathelife/questionnaire-engine';
import { Answers } from '@breathelife/types';

export abstract class PartiesEntityGenerator<E, S> {
  readonly answersResolver: NodeIdAnswersResolver;
  readonly entitySelector: S;

  constructor(answersResolver: NodeIdAnswersResolver, entitySelector: S) {
    this.answersResolver = answersResolver;
    this.entitySelector = entitySelector;
  }

  public abstract get(answers: Answers): E;
}
