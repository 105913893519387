import { NodeIdAnswersResolver } from '@breathelife/questionnaire-engine';
import {
  ProposedInsuredEntity,
  ProposedInsuredSelector,
  CollectionInstanceIdentifier,
  CollectionInstanceIdentifiers,
  Answers,
} from '@breathelife/types';

import { InvalidProposedInsuredError } from '../errors/invalidProposedInsuredError';
import { isValidDateString, isYesNoValue, isGenderAtBirth, isTimeOfDay } from '../helpers/answerTypeValidation';
import { PartiesEntityGenerator } from './PartiesEntityGenerator';

export class ProposedInsuredEntityGenerator extends PartiesEntityGenerator<
  ProposedInsuredEntity,
  ProposedInsuredSelector
> {
  private readonly collectionInstanceIdentifiers: CollectionInstanceIdentifiers;

  constructor(
    answersResolver: NodeIdAnswersResolver,
    entitySelector: ProposedInsuredSelector,
    insuredPersonIndex: CollectionInstanceIdentifier = 0,
  ) {
    super(answersResolver, entitySelector);
    this.collectionInstanceIdentifiers = { ['insured-people']: insuredPersonIndex };
  }

  private getAnswerForSelector(answers: Answers, selector: string): unknown {
    return this.answersResolver.getAnswer(answers, selector, this.collectionInstanceIdentifiers);
  }

  get(answers: Answers): ProposedInsuredEntity {
    const title = this.getAnswerForSelector(answers, this.entitySelector.title);
    const firstName = this.getAnswerForSelector(answers, this.entitySelector.firstName);
    const middleName = this.getAnswerForSelector(answers, this.entitySelector.middleName);
    const lastName = this.getAnswerForSelector(answers, this.entitySelector.lastName);
    const suffix = this.getAnswerForSelector(answers, this.entitySelector.suffix);
    const email = this.getAnswerForSelector(answers, this.entitySelector.email);
    const phoneNumber = this.getAnswerForSelector(answers, this.entitySelector.phoneNumber);
    const dateOfBirth = this.getAnswerForSelector(answers, this.entitySelector.dateOfBirth);
    const gender = this.getAnswerForSelector(answers, this.entitySelector.gender);
    const isTobaccoUser = this.getAnswerForSelector(answers, this.entitySelector.isTobaccoUser);
    const previousFirstName = this.entitySelector.previousFirstName
      ? this.getAnswerForSelector(answers, this.entitySelector.previousFirstName)
      : undefined;
    const previousLastName = this.entitySelector.previousLastName
      ? this.getAnswerForSelector(answers, this.entitySelector.previousLastName)
      : undefined;
    const occupation = this.entitySelector.occupation
      ? this.getAnswerForSelector(answers, this.entitySelector.occupation)
      : undefined;
    const canadianCitizen = this.entitySelector.canadianCitizen
      ? this.getAnswerForSelector(answers, this.entitySelector.canadianCitizen)
      : undefined;
    const birthCountry = this.entitySelector.birthCountry
      ? this.getAnswerForSelector(answers, this.entitySelector.birthCountry)
      : undefined;
    const birthProvince = this.entitySelector.birthProvince
      ? this.getAnswerForSelector(answers, this.entitySelector.birthProvince)
      : undefined;
    const address = this.entitySelector.address
      ? this.getAnswerForSelector(answers, this.entitySelector.address)
      : undefined;
    const address2 = this.entitySelector.address2
      ? this.getAnswerForSelector(answers, this.entitySelector.address2)
      : undefined;
    const city = this.entitySelector.city ? this.getAnswerForSelector(answers, this.entitySelector.city) : undefined;
    const provinceOrState = this.entitySelector.provinceOrState
      ? this.getAnswerForSelector(answers, this.entitySelector.provinceOrState)
      : undefined;
    const postalCode = this.entitySelector.postalCode
      ? this.getAnswerForSelector(answers, this.entitySelector.postalCode)
      : undefined;
    const contactTimeOfDay = this.entitySelector.contactTimeOfDay
      ? this.getAnswerForSelector(answers, this.entitySelector.contactTimeOfDay)
      : undefined;
    const surrogateId = this.entitySelector.surrogateId
      ? this.getAnswerForSelector(answers, this.entitySelector.surrogateId)
      : undefined;
    const tobaccoMostRecentUsage = this.entitySelector.tobaccoMostRecentUsage
      ? this.getAnswerForSelector(answers, this.entitySelector.tobaccoMostRecentUsage)
      : undefined;
    const marijuanaMostRecentUsage = this.entitySelector.marijuanaMostRecentUsage
      ? this.getAnswerForSelector(answers, this.entitySelector.marijuanaMostRecentUsage)
      : undefined;

    if (typeof title !== 'undefined' && typeof title !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: title should be a string or undefined',
      );
    }

    if (typeof firstName !== 'undefined' && typeof firstName !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: firstName should be a string or undefined',
      );
    }

    if (typeof middleName !== 'undefined' && typeof middleName !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: middleName should be a string or undefined',
      );
    }

    if (typeof lastName !== 'undefined' && typeof lastName !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: lastName should be a string or undefined',
      );
    }

    if (typeof suffix !== 'undefined' && typeof suffix !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: suffix should be a string or undefined',
      );
    }

    if (typeof email !== 'undefined' && typeof email !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: email should be a string or undefined',
      );
    }

    if (typeof phoneNumber !== 'undefined' && typeof phoneNumber !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: phoneNumber should be a string or undefined',
      );
    }

    if (typeof dateOfBirth !== 'undefined' && !isValidDateString(dateOfBirth)) {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: dateOfBirth should be a valid date or undefined',
      );
    }

    if (typeof gender !== 'undefined' && !isGenderAtBirth(gender)) {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: gender at brith should be male, female or undefined',
      );
    }

    if (typeof isTobaccoUser !== 'undefined' && !isYesNoValue(isTobaccoUser)) {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: isTobaccoUser should be yes, no, or undefined',
      );
    }

    if (typeof previousFirstName !== 'undefined' && typeof previousFirstName !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: previousFirstName should be a string or undefined',
      );
    }

    if (typeof previousLastName !== 'undefined' && typeof previousLastName !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: previousLastName should be a string or undefined',
      );
    }

    if (typeof occupation !== 'undefined' && typeof occupation !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: occupation should be a string or undefined',
      );
    }

    if (typeof canadianCitizen !== 'undefined' && typeof canadianCitizen !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: canadianCitizen should be a string or undefined',
      );
    }

    if (typeof birthCountry !== 'undefined' && typeof birthCountry !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: birthCountry should be a string or undefined',
      );
    }

    if (typeof birthProvince !== 'undefined' && typeof birthProvince !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: birthProvince should be a string or undefined',
      );
    }

    if (typeof address !== 'undefined' && typeof address !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: address should be a string or undefined',
      );
    }

    if (typeof address2 !== 'undefined' && typeof address2 !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: address2 should be a string or undefined',
      );
    }

    if (typeof city !== 'undefined' && typeof city !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: city should be a string or undefined',
      );
    }

    if (typeof provinceOrState !== 'undefined' && typeof provinceOrState !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: provinceOrState should be a string or undefined',
      );
    }

    if (typeof postalCode !== 'undefined' && typeof postalCode !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: postalCode should be a string or undefined',
      );
    }

    if (typeof contactTimeOfDay !== 'undefined' && !isTimeOfDay(contactTimeOfDay)) {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: contactTimeOfDay should be day, night, all, or undefined',
      );
    }
    if (typeof surrogateId !== 'undefined' && typeof surrogateId !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: surrogateId should be a string or undefined',
      );
    }

    if (typeof tobaccoMostRecentUsage !== 'undefined' && typeof tobaccoMostRecentUsage !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: tobaccoMostRecentUsage should be a string or undefined',
      );
    }

    if (typeof marijuanaMostRecentUsage !== 'undefined' && typeof marijuanaMostRecentUsage !== 'string') {
      throw new InvalidProposedInsuredError(
        'Error while getting the ProposedInsured entity: marijuanaMostRecentUsage should be a string or undefined',
      );
    }

    return {
      title,
      firstName,
      middleName,
      lastName,
      suffix,
      email,
      phoneNumber,
      dateOfBirth: dateOfBirth ? new Date(dateOfBirth as string) : undefined,
      gender,
      isTobaccoUser,
      previousFirstName,
      previousLastName,
      occupation,
      canadianCitizen,
      birthCountry,
      birthProvince,
      address,
      address2,
      city,
      provinceOrState,
      postalCode,
      contactTimeOfDay,
      surrogateId,
      tobaccoMostRecentUsage,
      marijuanaMostRecentUsage,
    };
  }
}
