import { AdvisorEntity } from '@breathelife/types';

import { User } from '../types/User';

export class AdvisorEntityGenerator {
  constructor() {}
  get(user: User, carrierOrMga?: string): AdvisorEntity {
    const firstName = user.firstName;
    const lastName = user.lastName;
    const customId = user.customId;
    const email = user.emailContact || user.emailLogin;
    const phoneNumber = user.phoneNumber;

    return {
      firstName,
      lastName,
      customId,
      email,
      phoneNumber,
      carrierOrMga,
    };
  }
}
