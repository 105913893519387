export class InvalidProposedInsuredError extends Error {
  constructor(message: string) {
    super(message);

    this.name = this.constructor.name;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidProposedInsuredError);
    }

    Object.setPrototypeOf(this, new.target.prototype);
  }
}
