import _ from 'lodash';

import { NodeIdAnswersResolver } from '@breathelife/questionnaire-engine';
import { Localizable, PaymentFrequency, PaymentSelector, ProductsEntity, QuoteInfo, Answers } from '@breathelife/types';

import { InsuranceProduct } from '../types/InsuranceProduct';

export class ProductsEntityGenerator {
  private readonly answersResolver: NodeIdAnswersResolver;

  constructor(answersResolver: NodeIdAnswersResolver) {
    this.answersResolver = answersResolver;
  }

  get<T extends string | Localizable>(
    answers: Answers,
    insuranceProducts: InsuranceProduct<T>[],
    paymentSelector: PaymentSelector | null,
    quotes?: QuoteInfo,
    hideRidersPremium?: boolean,
  ): ProductsEntity<T> {
    let paymentFrequency, productPaymentPremium: any;
    const selectedProductsAndRiders = insuranceProducts.filter((insuranceProduct) => {
      const productSelector = insuranceProduct.selector;
      if (!productSelector || !insuranceProduct.productId || !this.answersResolver.knowsId(productSelector.id))
        return false;

      const productSelectionAnswer = this.answersResolver.getAnswer(answers, productSelector.id);

      if (Array.isArray(productSelectionAnswer)) {
        return productSelectionAnswer.includes(insuranceProduct.productId);
      } else {
        return productSelectionAnswer === insuranceProduct.productId;
      }
    });

    if (paymentSelector) {
      paymentFrequency = this.answersResolver.getAnswer(answers, paymentSelector?.paymentFrequency);
      productPaymentPremium = this.answersResolver.getAnswer(answers, paymentSelector?.paymentPremium);
    }

    const sortedProductsAndRiders = selectedProductsAndRiders.sort((productA, productB) => {
      const firstIndex = productA.index || Number.MAX_VALUE;
      const secondIndex = productB.index || Number.MAX_VALUE;
      return firstIndex - secondIndex;
    });

    const productsEntity: ProductsEntity<T> = sortedProductsAndRiders.reduce(
      (entity: ProductsEntity<T>, insuranceProduct) => {
        const productId = insuranceProduct.productId;
        if (!productId) return entity;

        const productSelector = insuranceProduct.selector;

        let coverageAmount = productSelector?.coverageAmount
          ? this.answersResolver.getAnswer(answers, productSelector.coverageAmount)
          : 0;

        const productPremium = quotes?.quotePerProduct[productId];
        const productSelectorPremium =
          productSelector?.premium && this.answersResolver.getAnswer(answers, productSelector.premium);

        let premium = productPremium || productSelectorPremium || productPaymentPremium || 0;

        if (hideRidersPremium && insuranceProduct.isRider) {
          premium = 0;
          coverageAmount = 0;
        }

        const productOrRider = {
          productId,
          name: insuranceProduct.name,
          holdingForm: insuranceProduct.holdingForm,
          lineOfBusiness: insuranceProduct.lineOfBusiness,
          type: insuranceProduct.type,
          firm: _.omit(insuranceProduct.firm, ['id', 'carrierId', 'createdAt', 'updatedAt']),
          coverageAmount,
          premium,
        };

        if (insuranceProduct.isRider) {
          entity.riders.push(productOrRider);
        } else {
          entity.products.push(productOrRider);
        }

        entity.totalPremium = _.add(entity.totalPremium, premium);

        return entity;
      },
      {
        products: [],
        riders: [],
        totalPremium: 0,
        paymentFrequency: paymentFrequency || quotes?.paymentFrequency || PaymentFrequency.monthly,
      },
    );

    return productsEntity;
  }
}
