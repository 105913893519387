import { OwnerEntity, OwnerSelector, Answers } from '@breathelife/types';

import { isValidDateString } from '../helpers/answerTypeValidation';
import { PartiesEntityGenerator } from './PartiesEntityGenerator';

export class OwnerEntityGenerator extends PartiesEntityGenerator<OwnerEntity, OwnerSelector> {
  get(answers: Answers): OwnerEntity {
    const firstName: unknown = this.answersResolver.getAnswer(answers, this.entitySelector.firstName);
    const lastName: unknown = this.answersResolver.getAnswer(answers, this.entitySelector.lastName);
    const email: unknown = this.answersResolver.getAnswer(answers, this.entitySelector.email);
    const phoneNumber: unknown = this.answersResolver.getAnswer(answers, this.entitySelector.phoneNumber);
    const dateOfBirth: unknown = this.entitySelector.dateOfBirth
      ? this.answersResolver.getAnswer(answers, this.entitySelector.dateOfBirth)
      : undefined;

    const address: unknown = this.entitySelector.address
      ? this.answersResolver.getAnswer(answers, this.entitySelector.address)
      : undefined;
    const address2: unknown = this.entitySelector.address2
      ? this.answersResolver.getAnswer(answers, this.entitySelector.address2)
      : undefined;
    const city: unknown = this.entitySelector.city
      ? this.answersResolver.getAnswer(answers, this.entitySelector.city)
      : undefined;
    const provinceOrState: unknown = this.entitySelector.provinceOrState
      ? this.answersResolver.getAnswer(answers, this.entitySelector.provinceOrState)
      : undefined;
    const postalCode: unknown = this.entitySelector.postalCode
      ? this.answersResolver.getAnswer(answers, this.entitySelector.postalCode)
      : undefined;

    if (typeof firstName !== 'undefined' && typeof firstName !== 'string') {
      throw new Error('Error while getting the Owner entity: firstName should be a string or undefined');
    }

    if (typeof lastName !== 'undefined' && typeof lastName !== 'string') {
      throw new Error('Error while getting the Owner entity: lastName should be a string or undefined');
    }

    if (typeof email !== 'undefined' && typeof email !== 'string') {
      throw new Error('Error while getting the Owner entity: email should be a string or undefined');
    }

    if (typeof phoneNumber !== 'undefined' && typeof phoneNumber !== 'string') {
      throw new Error('Error while getting the Owner entity: phoneNumber should be a string or undefined');
    }

    if (typeof dateOfBirth !== 'undefined' && !isValidDateString(dateOfBirth)) {
      throw new Error(
        'Error while getting the ProposedInsured entity: dateOfBirth should be a valid date or undefined',
      );
    }

    if (typeof address !== 'undefined' && typeof address !== 'string') {
      throw new Error('Error while getting the Owner entity: address should be a string or undefined');
    }

    if (typeof address2 !== 'undefined' && typeof address2 !== 'string') {
      throw new Error('Error while getting the Owner entity: address2 should be a string or undefined');
    }

    if (typeof city !== 'undefined' && typeof city !== 'string') {
      throw new Error('Error while getting the Owner entity: city should be a string or undefined');
    }

    if (typeof provinceOrState !== 'undefined' && typeof provinceOrState !== 'string') {
      throw new Error('Error while getting the Owner entity: provinceOrState should be a string or undefined');
    }

    if (typeof postalCode !== 'undefined' && typeof postalCode !== 'string') {
      throw new Error('Error while getting the Owner entity: postalCode should be a string or undefined');
    }

    return {
      firstName,
      lastName,
      email,
      phoneNumber,
      dateOfBirth: dateOfBirth ? new Date(dateOfBirth as string) : undefined,
      address,
      address2,
      city,
      provinceOrState,
      postalCode,
    };
  }
}
